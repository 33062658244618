import tw from "twin.macro"

export const Wrapper = tw.div`
  pl-2 md:pl-0
`

export const Inner = tw.div`
  relative
`

export const Panel = tw.div`
  flex flex-col md:flex-row md:justify-between md:items-center mb-2-4 md:mb-4-8
`

export const Title = tw.h2`
  font-bold text-16 md:text-28 leading-1.25 md:leading-1.28 tracking-10 mb-1-6 md:mb-0 uppercase
`

export const InstagramLink = tw.div`
  text-14 leading-1.78 flex flex-row items-center -ml-0-6
`
