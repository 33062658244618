import React from "react"

import { withInstagram } from "./withInstagram"
import { Wrapper, Inner, Panel, Title, InstagramLink } from "./InstagramStyles"
import { Slider, Slide } from "../../Slider/Slider"
import { Next, Prev } from "../../Slider/SliderStyles"
import { Image } from "../../Image/Image"
import { Link } from "../../Link/Link"
import { Icon } from "../../Icon/Icon"

export const Instagram = withInstagram(
  ({ items, title, link }): JSX.Element => (
    <Wrapper>
      <Panel>
        <Title>{title}</Title>
        <InstagramLink>
          <Icon name="instagram" width="24" height="24" />
          <Link
            url={link.url}
            title={link.title}
            external={link.external}
            isExternal={link.isExternal}
          />
        </InstagramLink>
      </Panel>
      <Inner>
        <Slider
          layout={"instagram"}
          prevEl={`#instagram-carousel-prev`}
          nextEl={`#instagram-carousel-next`}
        >
          {items.map(item => (
            <Slide key={item.id}>
              <Image link={item.link} image={item.image} ratio={"1/1"} />
            </Slide>
          ))}
        </Slider>
        <Prev id={`instagram-carousel-prev`}>
          <Icon name={"arrowLeft"} />
        </Prev>
        <Next id={`instagram-carousel-next`}>
          <Icon name={"arrow"} />
        </Next>
      </Inner>
    </Wrapper>
  ),
)

export default Instagram
